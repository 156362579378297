.users-container {
  padding: 24px;
}

.ant-table-wrapper {
  background: white;
  border-radius: 8px;
}

.ant-card {
  border-radius: 8px;
} 