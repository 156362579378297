.side-menu {
  position: fixed !important;
  left: 0;
  height: 100vh;
  z-index: 1000;
  transition: all 0.2s !important;
  background: #001529 !important;
  margin-top: 64px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

.menu-items {
  border-right: none;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-icon {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.menu-label {
  font-size: 1rem;
  color: #333;
}

.toggle-button {
  padding: 16px;
  text-align: right;
  color: rgba(255, 255, 255, 0.65);
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
  background: rgba(255, 255, 255, 0.04);
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

.toggle-button:hover {
  color: #1890ff;
}

.ant-menu-item:hover {
  color: #1890ff !important;
}

.ant-menu-item-selected {
  background-color: #1890ff !important;
} 