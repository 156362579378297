.system-container {
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.system-alerts {
  margin-top: 24px;
}

.system-tabs {
  margin-top: -16px;
}

.system-tabs .ant-tabs-tab {
  padding: 12px 24px;
}

.ant-card {
  height: 100%;
}

.ant-card-head-title {
  white-space: normal;
  line-height: 1.4;
}

.ant-form-item-label > label {
  font-weight: 500;
}

@media (max-width: 768px) {
  .system-container {
    padding: 1rem;
  }
} 