.account-modal {
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px -24px 20px;
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  font-weight: 500;
}

.close-button {
  margin-right: -12px;
}

.account-form {
  margin-top: 24px;
}

.account-info {
  margin-bottom: 24px;
}

.account-info .ant-descriptions-item-label {
  width: 120px;
  background-color: #fafafa;
}

.form-actions {
  margin-bottom: 0;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

@media (max-width: 576px) {
  .account-modal {
    margin: 8px;
  }
  
  .modal-header {
    padding: 12px 16px;
  }
  
  .account-form {
    margin-top: 16px;
  }
} 