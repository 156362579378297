.support-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.page-title {
  margin-bottom: 24px !important;
  color: rgba(0, 0, 0, 0.85);
}

.search-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.error-alert {
  margin-top: 16px;
}

.ant-input-search {
  max-width: 100%;
}

.ant-input-search .ant-input {
  height: 40px;
  font-size: 16px;
}

.ant-input-search .ant-input-search-button {
  height: 40px;
  width: 40px;
}

.support-tools-card {
  background: #fff;
  border-radius: 8px;
}

.support-tabs {
  min-height: 300px;
}

.support-tabs .ant-tabs-nav {
  margin-bottom: 16px;
  background: #fafafa;
  padding: 8px 8px 0;
  border-radius: 8px 8px 0 0;
}

.support-tabs .ant-tabs-tab {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px 8px 0 0;
  transition: all 0.3s;
  margin: 0 4px;
}

.support-tabs .ant-tabs-tab:hover {
  color: #1890ff;
}

.support-tabs .ant-tabs-tab-active {
  background-color: #1890ff;
  border: none !important;
}

.support-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.support-tabs .ant-tabs-content {
  padding: 16px;
  background: #fff;
}

.user-info-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.info-card {
  margin: 0 !important;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.info-card .ant-card-body {
  padding: 16px;
}

.ant-space {
  width: 100%;
}

.user-info-card {
  margin-top: 24px;
  background: #fafafa;
}

.user-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.warning-alert {
  margin-top: 24px;
}

.loading-placeholder {
  text-align: center;
  padding: 24px;
  color: #666;
}

/* Responsive düzenlemeler */
@media (max-width: 768px) {
  .support-container {
    padding: 1rem;
  }
  
  .support-tabs .ant-tabs-tab {
    padding: 6px 12px;
    font-size: 14px;
  }

  .user-info-section {
    gap: 16px;
  }

  .user-info-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .page-title {
    font-size: 18px !important;
    margin-bottom: 16px !important;
  }
  
  .support-tabs .ant-tabs-nav {
    padding: 4px 4px 0;
  }

  .info-card .ant-card-body {
    padding: 12px;
  }
}

.ant-card {
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ant-divider {
  margin: 24px 0;
}

.ant-tabs-nav {
  margin-bottom: 24px !important;
}

.credit-history-table {
  margin-top: 24px;
}

.transfer-form,
.push-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 24px;
  background: #fafafa;
  border-radius: 8px;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.credit-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 24px;
  background: #fafafa;
  border-radius: 8px;
}

.credit-form .ant-form-item:last-child {
  margin-bottom: 0;
}

.credit-form .ant-space {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
} 