.create-category-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.preview-video,
.preview-image {
  margin-top: 16px;
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-card-head-title {
  font-size: 1.5rem;
} 