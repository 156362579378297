.user-attributes-container {
  padding: 16px;
  max-width: 1400px;
  margin: 0 auto;
}

.filters-section {
  background: #fff;
}

.filters-section .ant-card-body {
  padding: 16px;
}

.stat-card {
  height: 100%;
  background: #fff;
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.stat-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.stat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  font-size: 16px;
  margin-right: 8px;
}

.stat-title {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
  flex: 1;
}

.stat-value {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 4px;
}

.stat-subtitle {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

/* Card styles */
.ant-card {
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

/* Table styles */
.ant-table-wrapper {
  background: white;
  border-radius: 8px;
}

.ant-table {
  border-radius: 8px;
}

/* Filter styles */
.ant-select {
  border-radius: 6px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #4facfe;
}

.ant-picker {
  border-radius: 6px;
}

.ant-picker:hover {
  border-color: #4facfe;
}

/* Loading state */
.ant-card-loading-content {
  padding: 12px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .user-attributes-container {
    padding: 12px;
  }

  .stat-value {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .filters-section .ant-card-body {
    padding: 12px;
  }

  .stat-value {
    font-size: 18px;
  }
} 