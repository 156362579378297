.feed-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.filter-row {
  margin-bottom: 24px;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-cell {
  vertical-align: top;
}

.ant-image {
  width: 100%;
}

.ant-select-disabled {
  opacity: 1 !important;
}

.ant-select-disabled .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.85) !important;
} 