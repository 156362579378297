.categories-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.ant-table-cell .ant-space {
  gap: 8px;
}

.ant-card-head-title {
  font-size: 1.2rem;
  font-weight: 500;
} 