.video-detail-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.info-card,
.preview-card {
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.video-container {
  margin-bottom: 24px;
  text-align: center;
  max-width: 100%;
  background: #000;
  border-radius: 8px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* Default 16:9 aspect ratio */
  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #000;
}

.thumbnail-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-thumbnail {
  max-width: 100%;
  max-height: 600px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.input-image-section {
  margin-top: 24px;
}

.input-image-section h3 {
  margin-bottom: 16px;
  font-size: 16px;
  color: #1f1f1f;
  text-align: center;
}

.input-image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* 1:1 aspect ratio container */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
  background: #f0f0f0;
  max-width: 500px; /* Maksimum genişlik sınırı */
}

.input-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #000;
}

.prompt-section,
.error-section {
  margin-top: 24px;
}

.prompt-section h3,
.error-section h3 {
  margin-bottom: 8px;
  color: #1f1f1f;
}

.error-text {
  color: #ff4d4f;
}

.status-section {
  padding: 16px 0;
}

.status-item {
  margin-bottom: 24px;
}

.status-item h4 {
  margin-bottom: 12px;
  color: #1f1f1f;
}

.delete-button {
  margin-top: 24px;
}

.video-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.error-message {
  text-align: center;
  color: #ff4d4f;
  padding: 2rem;
}

.ant-descriptions {
  background: white;
}

.ant-descriptions-item-label {
  width: 150px;
  font-weight: 500;
  background-color: #fafafa;
}

.ant-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.ant-tag .anticon {
  font-size: 14px;
}

.ant-divider {
  margin: 24px 0;
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  min-width: 300px;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.ant-alert {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-card-head-title {
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .video-detail-container {
    padding: 1rem;
  }
} 