.explore-detail-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.media-preview-section {
  background: #fafafa;
  padding: 12px;
  border-radius: 8px;
  height: 100%;
}

.media-preview-section h3 {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.media-preview {
  position: relative;
  margin-bottom: 16px;
  background: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}

.media-preview video,
.media-preview img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 8px;
}

.upload-button {
  width: 100%;
}

.upload-button .ant-upload {
  width: 100%;
}

.upload-button .ant-btn {
  width: 100%;
}

.form-section {
  background: #fafafa;
  padding: 16px;
  border-radius: 8px;
  height: 100%;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-card-head-title {
  font-size: 1.2rem;
}

@media (max-width: 1400px) {
  .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .explore-detail-container {
    padding: 1rem;
  }
  
  .media-preview video,
  .media-preview img {
    height: 120px;
  }
} 