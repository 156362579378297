.explore-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-row:hover {
  background-color: #fafafa;
} 