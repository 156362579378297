.create-explore-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.preview-video,
.preview-image {
  margin-top: 16px;
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
}

.ant-upload-select {
  width: 100%;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-card-head-title {
  font-size: 1.5rem;
}

.explore-detail-container {
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
}

.media-preview-section {
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 16px;
}

.media-preview-section h3 {
  margin-bottom: 16px;
  font-size: 16px;
  color: #1f1f1f;
}

.media-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-container {
  width: 100%;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
  background: #fafafa;
}

.preview-container video,
.preview-container img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  border-radius: 4px;
}

.upload-button {
  width: 100%;
  margin-bottom: 8px;
}

.form-section {
  padding: 0 16px;
} 