.user-detail-container {
  padding: 16px;
  max-width: 1400px;
  margin: 0 auto;
}

/* Card Styles */
.ant-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: none;
}

.ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 24px;
}

.ant-card-head-title {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.ant-card-body {
  padding: 0;
}

/* User Info Section */
.user-info-section {
  padding: 24px;
  background: white;
}

/* Detail Item Styles */
.detail-item {
  height: 100%;
  padding: 16px;
  background: #f9fafb;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.detail-item:hover {
  background: #f3f4f6;
}

.detail-label {
  font-size: 13px;
  font-weight: 500;
  color: #6b7280;
  margin-bottom: 8px;
}

.detail-value {
  min-height: 28px;
  display: flex;
  align-items: center;
}

.detail-value.with-copy {
  justify-content: space-between;
}

.text-value {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
}

/* Stats Row Styles */
.stats-row {
  display: flex;
  gap: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.stat-label {
  font-size: 13px;
  color: #6b7280;
}

.stat-number {
  font-size: 14px;
  font-weight: 600;
  color: #111827;
}

/* Tag Styles */
.ant-tag {
  margin: 0;
  font-size: 13px;
  padding: 4px 12px;
  height: 28px;
  line-height: 20px;
  font-weight: 500;
  border: none;
}

/* Copy Icon */
.copy-icon {
  color: #9ca3af;
  cursor: pointer;
  font-size: 15px;
  padding: 6px;
  transition: all 0.2s;
  border-radius: 4px;
}

.copy-icon:hover {
  color: #1890ff;
  background: rgba(24, 144, 255, 0.1);
}

/* Tabs Styles */
.detail-tabs {
  padding: 0 24px 24px;
}

.ant-tabs-nav {
  margin-bottom: 16px !important;
  padding: 0 !important;
}

.ant-tabs-tab {
  padding: 8px 0 !important;
  margin: 0 24px 0 0 !important;
  font-size: 14px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff !important;
  font-weight: 600;
}

/* Table Styles */
.ant-table-wrapper {
  margin-top: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.ant-table {
  background: white;
}

.ant-table-thead > tr > th {
  background: #f9fafb !important;
  font-weight: 600;
  color: #374151;
}

/* Loading State */
.user-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .user-info-section {
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .detail-item {
    padding: 12px;
  }

  .stats-row {
    flex-direction: column;
    gap: 8px;
  }
}

/* User Info Grid */
.user-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 24px;
}

/* Info Group Styles */
.info-group {
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 16px;
}

.info-group-title {
  font-size: 14px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.info-group-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Info Item Styles */
.info-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.info-label {
  width: 120px;
  font-size: 13px;
  color: #6b7280;
  flex-shrink: 0;
}

.info-value {
  flex: 1;
  font-size: 14px;
  color: #1f2937;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Tag Styles */
.info-value .ant-tag {
  margin: 0;
  font-size: 13px;
  padding: 4px 12px;
  height: 28px;
  line-height: 20px;
  font-weight: 500;
}

/* Copy Icon */
.copy-icon {
  color: #6b7280;
  cursor: pointer;
  font-size: 15px;
  padding: 4px;
  transition: all 0.2s;
}

.copy-icon:hover {
  color: #1890ff;
}

/* Card Styles */
.ant-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
}

.ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 24px;
  min-height: 56px;
}

.ant-card-head-title {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
}

.ant-card-body {
  padding: 0;
}

/* Tabs Styles */
.detail-tabs {
  padding: 0 24px 24px;
}

.ant-tabs-nav {
  margin-bottom: 16px !important;
}

.ant-tabs-tab {
  padding: 8px 0 !important;
  margin: 0 16px 0 0 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff !important;
  font-weight: 600;
}

/* Loading State */
.user-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .user-info-grid {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px;
  }
}

@media (max-width: 640px) {
  .info-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .info-label {
    width: 100%;
  }

  .info-value {
    width: 100%;
  }

  .user-info-grid {
    padding: 12px;
  }
}

.user-detail-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-detail-card .ant-card-head-title {
  font-size: 1.5rem;
  color: #2c3e50;
}

.user-detail-error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.user-detail-error {
  color: #d32f2f;
}

.truncated-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncated-text:hover {
  text-decoration: underline;
}

/* Description styles */
.ant-descriptions-bordered {
  background: white;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: none;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  width: 25%;
  background: white !important;
  font-weight: 600;
  color: #1f2937;
  font-size: 14px;
  padding: 16px !important;
  border-right: none !important;
  border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-item-content {
  width: 75%;
  padding: 16px !important;
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-row:last-child .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-row:last-child .ant-descriptions-item-content {
  border-bottom: none;
}

/* Credit and Moderation items styling */
.credit-item {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-right: 32px;
}

.credit-label {
  color: #4b5563;
  font-size: 14px;
}

.credit-value {
  font-weight: 600;
  color: #1f2937;
  font-size: 14px;
}

/* Table styles */
.ant-table-wrapper {
  background: white;
  border-radius: 8px;
}

.ant-image {
  display: inline-block;
}

.ant-image img {
  border-radius: 4px;
}

.user-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.user-detail-header h2 {
  margin: 0;
  color: #2c3e50;
}

.user-status {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  background: #e8f5e9;
  color: #2e7d32;
}

.user-detail-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-group {
  margin-bottom: 1rem;
}

.info-group label {
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.info-group p {
  margin: 0;
  color: #2c3e50;
  font-size: 1rem;
}

/* Tag styles */
.ant-descriptions-item-content .ant-tag {
  margin: 0;
  font-size: 13px;
  padding: 4px 12px;
  height: 28px;
  line-height: 20px;
  font-weight: 500;
}

/* Credit and Moderation items styling */
.credit-item {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-right: 32px;
}

.credit-label {
  color: #4b5563;
  font-size: 14px;
}

.credit-value {
  font-weight: 600;
  color: #1f2937;
  font-size: 14px;
}

/* Copy icon styling */
.copy-icon {
  color: #4b5563;
  cursor: pointer;
  font-size: 15px;
  padding: 4px;
  transition: all 0.2s;
  margin-left: 4px;
}

.copy-icon:hover {
  color: #1890ff;
}

/* Card styles */
.ant-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
}

.ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 24px;
  min-height: 56px;
}

.ant-card-head-title {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
}

.ant-card-body {
  padding: 0;
}

/* Table styles */
.detail-tabs {
  padding: 0 24px 24px;
}

.ant-table-wrapper {
  margin-top: 8px;
}

.ant-tabs-nav {
  margin-bottom: 16px !important;
}

.ant-tabs-tab {
  padding: 8px 0 !important;
  margin: 0 16px 0 0 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff !important;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    width: 100%;
    padding: 12px !important;
  }

  .credit-item {
    margin-right: 16px;
    margin-bottom: 8px;
  }
} 