.videos-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.ant-progress {
  margin: 0;
  width: 100%;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-row:hover {
  background-color: #fafafa;
}

.ant-typography {
  color: rgba(0, 0, 0, 0.85);
}

/* Progress bar styles */
.ant-progress-inner {
  background-color: #f5f5f5;
}

.ant-progress-bg {
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
}

/* Table cell vertical alignment */
.ant-table-cell {
  vertical-align: middle !important;
}

/* Tooltip styles */
.ant-tooltip-inner {
  max-width: 500px;
  white-space: pre-wrap;
} 