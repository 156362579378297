.app {
  min-height: 100vh;
}

/* Ana layout container */
.ant-layout {
  min-height: 100vh;
}

/* İç layout container */
.ant-layout-has-sider {
  min-height: 100vh;
}

/* Sidebar */
.ant-layout-sider {
  min-height: 100vh;
  position: fixed !important;
  left: 0;
  height: 100%;
  z-index: 1000;
  transition: all 0.2s !important;
}

/* Collapse animasyonu için */
.ant-layout-sider-collapsed {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

/* Ana içerik alanı */
.content {
  margin-top: 64px;
  margin-left: 200px;
  padding: 24px;
  background: #f5f5f5;
  min-height: calc(100vh - 64px);
  transition: all 0.2s;
}

.content-collapsed {
  margin-left: 80px;
}

/* Search Input Styles */
.ant-input-search .ant-input {
  text-align: left;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.ant-input-search .ant-input::placeholder {
  line-height: 40px;
}

/* Placeholder'ı ortada tutmak için input'a yazı yazıldığında sola hizalama */
.ant-input-search .ant-input:not(:placeholder-shown) {
  text-align: left;
  padding-left: 16px;
}

/* Search ikonunu dikeyde ortala */
.ant-input-search .ant-input-suffix {
  height: 100%;
  display: flex;
  align-items: center;
}

/* Search button'u dikeyde ortala */
.ant-input-search .ant-input-search-button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
} 