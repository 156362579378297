.account-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.account-tabs {
  margin-top: -16px;
}

.account-tabs .ant-tabs-tab {
  padding: 12px 24px;
}

.error-text {
  font-size: 12px;
  max-width: 200px;
  word-break: break-word;
}

.ant-table-cell .ant-space-vertical {
  gap: 4px !important;
}

@media (max-width: 768px) {
  .account-container {
    padding: 1rem;
  }
}

.account-page {
  padding: 24px;
  max-width: 100%;
  margin: 0 auto;
}

.account-card {
  height: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.card-header h5.ant-typography {
  margin-bottom: 0;
}

.account-table {
  width: 100%;
}

.account-table .ant-table-container {
  border-radius: 8px;
}

.status-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.ant-table-cell {
  vertical-align: top;
}

/* Responsive düzenlemeler */
@media (max-width: 1400px) {
  .account-page {
    padding: 16px;
  }
}

@media (max-width: 576px) {
  .card-header {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }
  
  .card-header button {
    width: 100%;
  }
  
  .error-text {
    max-width: 150px;
  }
}

/* Table scroll bar styling */
.ant-table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}

.ant-table-body::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 3px;
} 