.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background: linear-gradient(90deg, #1a1a1a 0%, #2d2d2d 100%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 64px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.navbar-brand:hover {
  opacity: 0.9;
}

.logo {
  color: #fff;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  background: linear-gradient(
    to right,
    #4facfe,
    #00f2fe,
    #9f44d3,
    #e1133c,
    #4facfe
  );
  background-size: 500% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flowingGradient 15s linear infinite;
}

@keyframes flowingGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 500% 50%;
  }
}

.navbar-menu {
  flex: 1;
  display: flex;
  justify-content: center;
  background: transparent;
  border-bottom: none;
  margin: 0 48px;
}

.navbar-menu .ant-menu-item {
  color: #fff;
  font-size: 15px;
  padding: 0 24px;
  margin: 0 4px;
  height: 64px;
  line-height: 64px;
  border-bottom: 2px solid transparent;
}

.navbar-menu .ant-menu-item:hover {
  color: #4facfe !important;
  border-bottom: 2px solid #4facfe !important;
}

.navbar-menu .ant-menu-item-selected {
  color: #4facfe !important;
  border-bottom: 2px solid #4facfe !important;
  background: transparent !important;
}

.navbar-menu .ant-menu-item .anticon {
  margin-right: 8px;
  font-size: 16px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.logout-button {
  background: linear-gradient(45deg, #4facfe 0%, #00f2fe 100%);
  border: none;
  height: 36px;
  padding: 0 20px;
  border-radius: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(79, 172, 254, 0.3);
}

.logout-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(79, 172, 254, 0.4);
  background: linear-gradient(45deg, #4facfe 0%, #00f2fe 100%) !important;
  opacity: 0.9;
}

.logout-button .anticon {
  font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    padding: 0 12px;
  }

  .navbar-menu {
    margin: 0 12px;
  }

  .navbar-menu .ant-menu-item {
    padding: 0 12px;
  }

  .logo {
    font-size: 20px;
  }
}

.search-input {
  width: 300px;
  border-radius: 4px;
}

.navbar-logo {
  height: 40px;
  width: auto;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
} 