.dashboard-container {
  padding: 24px;
}

.dashboard-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dashboard-charts {
  margin-top: 24px;
}

.ant-card {
  height: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ant-statistic-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-statistic-content {
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 16px;
  }
  
  .ant-statistic-content {
    font-size: 20px;
  }
} 